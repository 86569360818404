import React, { useContext, useRef } from 'react'
import styled from 'styled-components'
import Image from 'gatsby-image'
import Icon from './Icon'
import Price from './Price'
import { DictionaryContext, StoreContext } from '../contexts'
import {
  mobileVW,
  desktopVW,
  color,
  zIndex,
  desktopBreakpoint
} from '../styles'
import gsap from 'gsap'
import detectIt from 'detect-it'

const ProductThumbnail = ({
  product: { productImages, title, shopifyProduct, preorder }
}) => {
  const { currency } = useContext(StoreContext)
  const dictionary = useContext(DictionaryContext)
  const hoverRef = useRef()
  const productImageRef = useRef()
  const monogramRef = useRef()
  const tl = useRef()

  const onMouseEnter = (e) => {
    if (tl.current) tl.current.kill()
    if (detectIt.primaryInput !== 'touch') {
      tl.current = gsap.timeline()
        .to(hoverRef.current, 0.8, { visibility: 'inherit', x: 0, ease: 'expo.out' }, 0)
        .to(monogramRef.current, 0.6, { x: '-50%', y: '-20%', scale: 1, rotate: '-10deg', ease: 'expo.out' }, 0.15)
        .to(productImageRef.current, 0.6, { rotate: '5deg', ease: 'expo.out' }, 0.15)
    }
  }

  const onMouseLeave = (e) => {
    if (detectIt.primaryInput !== 'touch') {
      tl.current = gsap.timeline({ delay: 0.2 })
        .to(monogramRef.current, 0.8, { x: '0%', y: '0%', scale: 0.8, rotate: '0deg', ease: 'quart.inOut', clearProps: 'all' }, 0)
        .to(productImageRef.current, 0.8, { x: '0%', rotate: '0deg', ease: 'quart.inOut', clearProps: 'all' }, 0)
        .to(hoverRef.current, 1, { x: '100%', ease: 'quart.inOut', clearProps: 'all' }, 0.1)
    }
  }

  return (
    <Article onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <OnHoverWrapper ref={hoverRef} className='on-hover-layer'>
        <div className='logo-sentence'>
          <Icon use name='logo' />
          <Icon use name='logo' />
        </div>
        <Image
          fluid={productImages[0].backgroundImage.fluid}
          alt={productImages[0].backgroundImage.title}
        />
      </OnHoverWrapper>
      {preorder && (<Preorder>{dictionary.preorder}</Preorder>)}
      <ImageWrapper ref={productImageRef} className='product-thumbnail-image-wrapper'>
        <Icon ref={monogramRef} name='logo-monogram-trans' className='logo-monogram' />
        <Image
          fluid={productImages[0].foregroundImage ? productImages[0].foregroundImage.fluid : null}
          alt={productImages[0].foregroundImage ? productImages[0].foregroundImage.title : null}
        />
      </ImageWrapper>
      <ProductTitle>{title}</ProductTitle>
      <ProductPrice>
        <Price shopifyProduct={shopifyProduct} label />
      </ProductPrice>
    </Article>
  )
}

const Article = styled.article`
  height: 100%;
  position: relative;
`

const Preorder = styled.div`
  position: absolute;
  background-color: ${color.yellow};
  color: ${color.black};
  display: inline-block;
  transform: rotate(-3.17deg);
  top: ${mobileVW(24)};
  left: ${mobileVW(29)};
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(15)};
  padding: 0 ${mobileVW(4.5)};
  font-weight: bold;

  @media (min-width: ${desktopBreakpoint}) {
    top: ${desktopVW(24)};
    left: ${desktopVW(29)};
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(15)};
    padding: 0 ${desktopVW(4.5)};
  }
`

const ImageWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: ${mobileVW(267)};
  top: 47%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${zIndex.low + 1};

  .logo-monogram {
    display: none;
  }

  .gatsby-image-wrapper {
    height: 100%;

    img {
      object-fit: contain !important;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    position: relative;
    height: ${desktopVW(295)};
    transform-origin: 50% 100%;
    top: 50%;

    .logo-monogram {
      display: block;
      position: absolute;
      fill: ${color.yellow};
      height: ${desktopVW(91)};
      width: ${desktopVW(91)};
      margin-left: ${desktopVW(-45)};
      top: ${desktopVW(5)};
      left: 50%;
      z-index: 0;
      transform: scale(0.8);
      transform-origin: 50% 100%;
    }
  }
`

const OnHoverWrapper = styled.div`
  visibility: hidden;
  transform: translateX(100%);

  @media (min-width: ${desktopBreakpoint}) {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;

    .logo-sentence {
      display: flex;
      justify-content: flex-end;
      position: absolute;
      fill: ${color.yellow};
      top: 50%;
      left: 0;
      z-index: ${zIndex.low};
      animation-name: translate-logo-thumb;
      animation-duration: 4s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;

      svg {
        width: ${desktopVW(1331)};
        height: ${desktopVW(160)};
        padding-right: ${desktopVW(20)};
      }
    }

    .gatsby-image-wrapper {
      height: 100%;

      img {
        object-fit: cover !important;
      }
    }
  }
`

const ProductTitle = styled.p`
  position: absolute;
  bottom: ${mobileVW(30)};
  left: ${mobileVW(30)};
  font-size: ${mobileVW(14)};
  line-height: ${mobileVW(16)};
  width: 66%;

  @media (min-width: ${desktopBreakpoint}) {
    bottom: ${desktopVW(30)};
    left: ${desktopVW(30)};
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(14)};
  }
`

const ProductPrice = styled.div`
  position: absolute;
  bottom: ${mobileVW(32)};
  right: ${mobileVW(29)};
  font-size: ${mobileVW(12)};
  line-height: ${mobileVW(17)};

  @media (min-width: ${desktopBreakpoint}) {
    bottom: ${desktopVW(32)};
    right: ${desktopVW(29)};
    font-size: ${desktopVW(12)};
    line-height: ${desktopVW(17)};
  }
`

export default ProductThumbnail
