import { useMemo } from 'react'
import { useSelector } from 'react-redux'

const useFilterProducts = (products) => {
  const { categoriesFilter, applyOnsFilter, colorsFilter } = useSelector(
    ({ selections }) => selections
  )

  const filterByCategories = () => {
    if (categoriesFilter.length > 0) {
      const filteredProdsByCategories = products.filter((product) => {
        const categoriesPerProduct = product.category.find(({ name }) =>
          categoriesFilter.includes(name)
        )
        if (categoriesPerProduct) return product
      })
      return filteredProdsByCategories
    } else {
      return products
    }
  }

  const filterByColor = () => {
    if (colorsFilter.length > 0) {
      const filteredProdsByColor = filterByCategories().filter((product) => {
        if (colorsFilter.includes(product.color.hexCode)) return product
      })
      return filteredProdsByColor
    } else {
      return filterByCategories()
    }
  }

  const filterProds = () => {
    if (applyOnsFilter.length > 0) {
      const filteredProdsByCare = filterByColor().filter((product) => {
        const typesPerProduct = product.applyOn.find(({ name }) =>
          applyOnsFilter.includes(name)
        )
        if (typesPerProduct) return product
      })
      return filteredProdsByCare
    } else {
      return filterByColor()
    }
  }

  return useMemo(() => filterProds())
}

export default useFilterProducts
